/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVwComunicados = /* GraphQL */ `
  query GetVwComunicados($personaId: Int!) {
    getVWComunicados(personaId: $personaId) {
      comunicadoId
      titulo
      comunicado
      tipo
      archivo
      personaId
      nombre
      apellidoPaterno
      apellidoMaterno
      fechaInicio
      fechaFin
      leido
    }
  }
`;
export const listVwRolRutasSemanal = /* GraphQL */ `
  query ListVwRolRutasSemanal($personaId: Int!, $diasCalendario: String!) {
    listVWRolRutasSemanal(
      personaId: $personaId
      diasCalendario: $diasCalendario
    ) {
      id
      personaId
      persona
      rolId
      rol
      proyectoId
      proyecto
      horaEntrada
      rutaId
      ruta
      rutaFechaId
      diasCalendario
      actividad
      duracion
      prioridad
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      eventoId
      latitude
      longitude
      evento
      fechaLanzamiento
      ubicacionFisicaId
      ubicacion
      estado
      ciudad
      avance
      modoPrueba
      direccion
      aplicaGeocerca
      distanciaGeocerca
      cadenaId
      formatoId
      asistenciaConFoto
      fechaInicial
      fechaFinal
    }
  }
`;
export const listVwVersiones = /* GraphQL */ `
  query ListVwVersiones($aplicacion: String!, $dispositivo: String!) {
    listVWVersiones(aplicacion: $aplicacion, dispositivo: $dispositivo) {
      aplicacion
      dispositivo
      version
    }
  }
`;
export const listVwRolRutasFull = /* GraphQL */ `
  query ListVwRolRutasFull($personaId: Int!) {
    listVWRolRutasFull(personaId: $personaId) {
      id
      personaId
      persona
      rolId
      rol
      proyectoId
      proyecto
      horaEntrada
      rutaId
      ruta
      rutaFechaId
      diasCalendario
      actividad
      duracion
      prioridad
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      eventoId
      latitude
      longitude
      evento
      fechaLanzamiento
      ubicacionFisicaId
      ubicacion
      estado
      ciudad
      avance
      modoPrueba
      direccion
      aplicaGeocerca
      distanciaGeocerca
      cadenaId
      formatoId
      asistenciaConFoto
      fechaInicial
      fechaFinal
    }
  }
`;
export const listVwFormulariosFull = /* GraphQL */ `
  query ListVwFormulariosFull($personaId: Int!) {
    listVWFormulariosFull(personaId: $personaId) {
      formularioId
      formulario
      proyectoId
      proyecto
      rutaId
      rutaFechaId
      ruta
      tareaId
      completado
      tipo
      personaId
      obligatorio
    }
  }
`;
export const listVwPreguntasFull = /* GraphQL */ `
  query ListVwPreguntasFull($personaId: Int!, $pagina: Int!) {
    listVWPreguntasFull(personaId: $personaId, pagina: $pagina) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVwProyectos = /* GraphQL */ `
  query ListVwProyectos($personaId: Int!) {
    listVWProyectos(personaId: $personaId) {
      proyectoId
      proyecto
      area
    }
  }
`;
export const listVwComunicados = /* GraphQL */ `
  query ListVwComunicados($personaId: Int!) {
    listVWComunicados(personaId: $personaId) {
      comunicadoId
      titulo
      comunicado
      tipo
      archivo
      personaId
      nombre
      apellidoPaterno
      apellidoMaterno
      fechaInicio
      fechaFin
      leido
    }
  }
`;
export const listVwPromotoria = /* GraphQL */ `
  query ListVwPromotoria($personaId: Int!) {
    listVWPromotoria(personaId: $personaId) {
      jefeId
      personaId
      persona
    }
  }
`;
export const listVwFormulariosCadenasFormatos = /* GraphQL */ `
  query ListVwFormulariosCadenasFormatos($personaId: Int!) {
    listVWFormulariosCadenasFormatos(personaId: $personaId) {
      formularioId
      cadenas
      formatos
    }
  }
`;
export const listVwFormulariosByRuta = /* GraphQL */ `
  query ListVwFormulariosByRuta($rutaFechaId: Int!) {
    listVWFormulariosByRuta(rutaFechaId: $rutaFechaId) {
      formularioId
      formulario
      proyectoId
      proyecto
      rutaId
      rutaFechaId
      ruta
      tareaId
      completado
      tipo
      personaId
      obligatorio
    }
  }
`;
export const listVwPreguntasByRutaFormulario = /* GraphQL */ `
  query ListVwPreguntasByRutaFormulario(
    $rutaFechaId: Int!
    $formularioId: Int!
  ) {
    listVWPreguntasByRutaFormulario(
      rutaFechaId: $rutaFechaId
      formularioId: $formularioId
    ) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVwPreguntas = /* GraphQL */ `
  query ListVwPreguntas($personaId: Int!) {
    listVWPreguntas(personaId: $personaId) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVwPreguntasCadenasFormatos = /* GraphQL */ `
  query ListVwPreguntasCadenasFormatos($personaId: Int!) {
    listVWPreguntasCadenasFormatos(personaId: $personaId) {
      preguntaId
      cadenas
      formatos
    }
  }
`;
export const listVwPreguntasRutasFechas = /* GraphQL */ `
  query ListVwPreguntasRutasFechas($personaId: Int!) {
    listVWPreguntasRutasFechas(personaId: $personaId) {
      preguntaId
      rutasFechas
    }
  }
`;
export const syncAsistencias = /* GraphQL */ `
  query SyncAsistencias(
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAsistencias(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAsistencias = /* GraphQL */ `
  query GetAsistencias($id: ID!) {
    getAsistencias(id: $id) {
      id
      rolRutaId
      personaId
      fecha
      posicionInicial
      imagenInicial {
        bucket
        key
        region
      }
      fechaInicial
      posicionFinal
      imagenFinal {
        bucket
        key
        region
      }
      fechaFinal
      estatus
      createdAt
      updatedAt
      pathImagenInicial
      imagenFirma {
        bucket
        key
        region
      }
      modoPrueba
      modelo
      version
      pathImagenFinal
      pathImagenFirma
      estatusFinal
      tienda
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAsistenciass = /* GraphQL */ `
  query ListAsistenciass(
    $id: ID
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAsistenciass(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRespuestas = /* GraphQL */ `
  query SyncRespuestas(
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRespuestas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRespuestas = /* GraphQL */ `
  query GetRespuestas($id: ID!) {
    getRespuestas(id: $id) {
      id
      rolRutaId
      personaId
      preguntaId
      pregunta
      respuesta
      imagen {
        bucket
        key
        region
      }
      estatus
      answerId
      localizacion
      tareaId
      createdAt
      updatedAt
      modoPrueba
      modelo
      version
      pathImagen
      formularioId
      fecha
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRespuestass = /* GraphQL */ `
  query ListRespuestass(
    $id: ID
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRespuestass(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByPersonaRolRuta = /* GraphQL */ `
  query ListAsistenciasByPersonaRolRuta(
    $personaId: String
    $rolRutaId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByPersonaRolRuta(
      personaId: $personaId
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByPersona = /* GraphQL */ `
  query ListAsistenciasByPersona(
    $personaId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByPersona(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByRolRuta = /* GraphQL */ `
  query ListAsistenciasByRolRuta(
    $rolRutaId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByRolRuta(
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersonaRolRuta = /* GraphQL */ `
  query ListRespuestasByPersonaRolRuta(
    $personaId: String
    $rolRutaId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersonaRolRuta(
      personaId: $personaId
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersona = /* GraphQL */ `
  query ListRespuestasByPersona(
    $personaId: String
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersona(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByFormularioTarea = /* GraphQL */ `
  query ListRespuestasByFormularioTarea(
    $personaId: String
    $rolRutaIdFormularioIdTareaId: ModelRespuestasByFormularioTareaCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByFormularioTarea(
      personaId: $personaId
      rolRutaIdFormularioIdTareaId: $rolRutaIdFormularioIdTareaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersonaEstatus = /* GraphQL */ `
  query ListRespuestasByPersonaEstatus(
    $personaId: String
    $estatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersonaEstatus(
      personaId: $personaId
      estatus: $estatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByRolRuta = /* GraphQL */ `
  query ListRespuestasByRolRuta(
    $rolRutaId: String
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByRolRuta(
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
