/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:06028cef-4360-4853-9757-9485599f056f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oaBDbsyN0",
    "aws_user_pools_web_client_id": "4bkjr57cd019gu7sde2rghd9b0",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://lwmi2ruoujezjaqx5vmdcwa7tu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "analitycsmobile8753a4c8bd154997b5e61ce3a19ab4da172610-release",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "a745a8efef9e447ea1dd642937c1730d",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
